$primary-hue: 1;
$primary-saturation: 85%;
$primary-lightness: 56%;

$primary-color: hsl($primary-hue, $primary-saturation, $primary-lightness);
$primary-color-hover: hsl($primary-hue, $primary-saturation + 1, $primary-lightness - 10);
$primary-color-outline: hsl($primary-hue, $primary-saturation + 10 , $primary-lightness + 4);
$primary-color-selected: hsl($primary-hue, $primary-saturation - 24, $primary-lightness - 45);
$primary-color-title: hsl($primary-hue, $primary-saturation - 24, $primary-lightness - 50);

$background-order-color-lg: linear-gradient(to bottom, hsl(1, 73%, 51%) 0%, hsl(1, 73%, 51%) 25%, hsl(1, 73%, 51%) 50%, hsl(2, 73%, 49%) 75%, hsl(2, 75%, 46%) 100%);
$background-order-color: linear-gradient(to bottom, hsl(1, 73%, 51%) 0%, hsl(1, 73%, 51%) 25%, hsl(1, 71%, 50%) 50%, hsl(1, 75%, 46%) 75%, hsl(2, 78%, 43%) 100%);

$myabc-logo-url: url('../images/logos/myabc-logotype-dk.svg') no-repeat;
$background-url: url('../images/banner-myabc-login-dk.jpg') no-repeat top right;
$icon-design-url: url('../images/icons/icn-design-dk.svg') no-repeat;
$background-order-url: url('../images/banner-myabc-area-dk.jpg') no-repeat 100% 50%;

$thank-you-page-background-url: url('../images/banner-thankyou-dk.jpg') no-repeat top right;
$thank-you-page-background-color: linear-gradient(224.71deg, hsl(7, 77, 54) 0%, hsl(1, 70, 49) 56%, hsl(1, 83, 37) 100%);

@import 'mixins';
@import 'app';
@import 'scrollbars';
@import "~bootstrap/scss/bootstrap";
@import 'vanillajs-datepicker/css/datepicker-bulma.css';
@import "bootstrap-related";
@import "~cropperjs";
@import 'error-layout';